import { ClientDto } from "common/types/account";
import { ReadAccount } from "./fetchAccounts";

export const mapToReadClient = (
  client: ClientDto,
  selected: boolean,
  readonly: boolean
): ReadAccount => ({
  ...client,
  selected,
  readonly,
});
