import {
  buildApiIO,
  criterias,
  ServiceParameters,
} from "Sgb4/common/api/api.common";
import { ClientDto, Properties, searchProperties } from "common/types/account";
import { getItemsByIds, search } from "common/api";
import { head, isEmpty, join, uniq } from "lodash";
import { toGetCriteria } from "common/criteria/account";
import { SearchType } from "Sgb4/DeprecatedTagPicker/SingleSelect/TagPicker";
import { BdrId } from "Sgb4/common/api/accounts/account.typings";
import {
  isStringEmpty,
  sanitizeSearchTerm,
} from "Sgb4/common/strings/stringUtils";

const rootUrl = "2.0/clients";
const privateRootUrl = "private/0.0/clients";
const baseUrl = (id: string | undefined) => (id ? `${rootUrl}/${id}` : rootUrl);
const privateBaseUrl = (id: string | undefined) =>
  id ? `${privateRootUrl}/${id}` : privateRootUrl;
const defaultProperties = ["clientIds", "country", "portfolio"];

export const getAccountsById = <T>(
  serviceParams: ServiceParameters,
  ids: string[],
  properties: Properties[] = ["clientIds", "country"],
  privateService = false
) => {
  return getItemsByIds<T>(
    buildApiIO(serviceParams, privateService ? privateBaseUrl : baseUrl)
  )(toGetCriteria(uniq(ids), properties));
};

export const getAccountByBdrId = (
  serviceParams: ServiceParameters,
  { bdrId, levels }: BdrId
) => {
  return search<ClientDto[]>(buildApiIO(serviceParams, baseUrl))({
    properties: searchProperties.join(","),
    sort: "smartSort",
    clientLegal: bdrId,
    levels: levels.join(","),
  });
};

export const getAccountsByBdrId = (
  serviceParams: ServiceParameters,
  bdrIds: string[],
  levels: string[]
) =>
  Promise.all(
    bdrIds.map(bdrId => getAccountByBdrId(serviceParams, { bdrId, levels }))
  );

export const searchAccountByTermAndLevel = (
  serviceParams: ServiceParameters,
  term: string,
  levels?: string[],
  limitBeforeSearch = 3,
  pageSize = 200
): Promise<ClientDto[]> => {
  const sanitized = sanitizeSearchTerm(term || "");
  return isStringEmpty(sanitized) || sanitized.length < limitBeforeSearch
    ? Promise.resolve([])
    : search<ClientDto[]>(buildApiIO(serviceParams, baseUrl))({
        search: sanitized,
        properties: defaultProperties.join(","),
        pageSize: !pageSize && pageSize <= 0 ? 200 : pageSize,
        sort: "smartSort",
        levels: !isEmpty(levels) ? join(levels, ",") : undefined,
      });
};

export const criteria = (searchBy: SearchType, terms: string) => ({
  ...(head(
    criterias.filter(({ condition }) => condition(searchBy))
  ) as any).value(terms),
  properties: searchProperties.join(","),
  sort: "smartSort",
});
