import { Component, h } from "preact";
import { ServiceParameters } from "Sgb4/common/api/api.common";
import { isEmpty, first, isArray, values, debounce, isEqual } from "lodash";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import { EntityType } from "Sgb4/newTagPicker/common/typings";
import { SingleSelectTagPicker } from "Sgb4/newTagPicker/SingleSelect/SingleSelectTagPicker";
import { renderLineItem } from "Sgb4/newTagPicker/renderers/accountRenderer";
import {
  fetchAccounts,
  fetchAccountsByterm,
  ReadAccount,
} from "ic-sgb4-simple-accounts-picker/fetchAccounts";
import { getAccountsById } from "Sgb4/common/api/accounts/account.api";
import { ClientDto } from "common/types/account";
import { Testable } from "@dcc-cli/ic-shared-components";
import { isStringEmpty } from "../Sgb4/common/strings/stringUtils";

export interface AccountPickerStateProps extends ErrorProps {
  serviceParams: ServiceParameters;
  placeholder: string;
  maxResultCount: number;
  selectedId: string;
  readonlyId: string;
  selectedBdrId?: string;
  readonlyBdrId?: string;
  levels?: string[];
  hostName: string;
  size?: string;
}

export interface AccountPickerDispatchProps {
  onChange: (account: ReadAccount) => void;
  onReady: (account?: ReadAccount) => void;
}

export type AccountPickerProps = AccountPickerStateProps &
  AccountPickerDispatchProps &
  Testable;

export interface AccountPickerState {
  selectedAccount?: ReadAccount;
  accounts: ReadAccount[];
  searchTerm: string;
  isLoading: boolean;
  hasError: boolean;
}

export class AccountPicker extends Component<
  AccountPickerProps,
  AccountPickerState
> {
  private handleOnSearch = debounce(
    (terms: string, selectedAccounts: ReadAccount[]) => {
      const { serviceParams, maxResultCount, levels } = this.props;

      this.setState(
        { searchTerm: terms, isLoading: true, accounts: [] },
        () => {
          fetchAccountsByterm(
            terms,
            { serviceParams, maxResultCount },
            selectedAccounts,
            levels
          )
            .then(accounts =>
              this.setState({ accounts, isLoading: false, hasError: false })
            )
            .catch(() => this.setState({ isLoading: false, hasError: true }));
        }
      );
    },
    500
  );

  constructor(props: AccountPickerProps) {
    super(props);
    this.state = {
      accounts: [],
      selectedAccount: undefined,
      searchTerm: "",
      isLoading: false,
      hasError: false,
    };
    const { selectedId, readonlyId } = this.props;

    if (!!selectedId && !!readonlyId) {
      throw new Error(
        `Both selectedId: ${selectedId} and readonlyId: ${readonlyId} exist`
      );
    }
  }

  public componentDidUpdate(previousProps: Readonly<AccountPickerProps>) {
    if (isEqual(previousProps.selectedId, this.props.selectedId)) {
      return;
    }

    if (isStringEmpty(this.props.selectedId)) {
      this.setState({ selectedAccount: null as any });
    } else {
      getAccountsById<ClientDto>(this.props.serviceParams, [
        this.props.selectedId,
      ]).then(selectedAccounts => {
        const selectedAccount = first<ClientDto>(selectedAccounts);
        this.setState({
          selectedAccount: selectedAccount
            ? { ...selectedAccount, selected: true }
            : undefined,
        });
      });
    }
  }

  public getChildContext() {
    return { hostName: this.props.hostName };
  }

  public componentDidMount() {
    const {
      serviceParams,
      selectedId,
      readonlyId,
      selectedBdrId,
      readonlyBdrId,
      levels,
      onReady,
    } = this.props;

    const params = {
      selectedIds: selectedId ? [selectedId] : [],
      readonlyIds: readonlyId ? [readonlyId] : [],
      selectedBdrIds: selectedBdrId ? [selectedBdrId] : [],
      readonlyBdrIds: readonlyBdrId ? [readonlyBdrId] : [],
      levels: levels ? levels : [],
    };

    if (values(params).some(value => isArray(value) && !isEmpty(value))) {
      const {
        selectedIds,
        readonlyIds,
        selectedBdrIds,
        readonlyBdrIds,
      } = params;
      fetchAccounts(serviceParams, {
        selectedIds,
        readonlyIds,
        selectedBdrIds,
        readonlyBdrIds,
        levels: params.levels,
      }).then(selectedAccounts =>
        this.setState({ selectedAccount: first(selectedAccounts) as any }, () =>
          onReady(this.state.selectedAccount)
        )
      );
    }
  }

  public render(
    {
      hostName,
      placeholder,
      maxResultCount,
      inError,
      errorMessage,
      onChange,
      e2eId,
      size,
    }: AccountPickerProps,
    { selectedAccount, accounts, isLoading, hasError }: AccountPickerState
  ) {
    return (
      <SingleSelectTagPicker<ReadAccount>
        selectedItem={selectedAccount}
        items={accounts}
        hostName={hostName}
        placeholder={placeholder}
        maxResultCount={maxResultCount}
        entityType={EntityType.Account}
        renderLineItem={renderLineItem}
        inError={inError}
        errorMessage={errorMessage}
        onSelect={onChange}
        onTermsChange={this.handleOnSearch}
        isLoading={isLoading}
        hasError={hasError}
        displayOption={"none"}
        e2eId={e2eId}
        size={size}
      />
    );
  }
}
