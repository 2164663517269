/**
 * Represents an account (usually used for our props or our events).
 *
 * This is the format standardised for SG-Dashboard communication between selectors and widgets.
 */
import {
  MaestroLevelSubType,
  MaestroLevelType,
} from "../../Sgb4/common/api/maestro/maestro.typing";

// tslint:disable:interface-name
export interface ClientOutput {
  icId: string;
  bdrCommercialId: string;
  bdrLegalId: string;
  /** Might be provided by selectors but should not be relied upon by widget taking the data in input */
  name?: string;
  bdrCommercialLevel?: string;
  bdrLegalLevel?: string;
  bdrMnemonic?: string;
  rctId?: string;
  icLevel?: string;
}

export interface MaestroClientOutput {
  bdrId?: string;
  fullLegalName?: string;
  type?: string;
  subType?: string;
  level?: string;
  isOperationView?: boolean;
  bdrMnemonic?: string;
}

export interface AccountId {
  id?: string;
  level?: string;
}

export interface AddressDto {
  street1?: string;
  street2?: string;
  street3?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  countryIso3?: string;
  type: string;
  priority?: string;
}

export interface BdrIdsDto {
  internalId?: AccountId;
  bdrCommercialId?: AccountId;
  bdrLegalId?: AccountId;
  bdrMnemonic?: string;
}

export interface ClientIdsDto {
  internalId?: AccountId;
  bdrCommercialId?: AccountId;
  bdrLegalId?: AccountId;
  rctId?: string;
  bdrMnemonic?: string;
}

export interface ClientDto {
  readonly: boolean;
  name: string;
  id: string;
  type?: string;
  isActive?: boolean;
  mergedTo?: string;
  bdrId?: BdrIdsDto;
  parent?: ParentClientDto;
  clientIds: ClientIdsDto;
  country?: CountryDto;
  target?: StrategicDto;
  strategic?: StrategicClientDto;
  tiering?: TieringDto;
  coverage?: CoverageDto[];
  mainAddress?: AddressDto;
  addresses?: AddressDto[];
  computedClientRootId?: string;
  portfolio?: PortfolioDto;
}

export interface MnemonicDto {
  bdrMnemonic: string;
}

export interface MaestroClientDto {
  id: string;
  bdrId: string;
  fullLegalName: string;
  type: MaestroLevelType;
  subType: MaestroLevelSubType;
  isOperationView: boolean;
}

export interface PrivateClientDto {
  rctId: string;
  siren: string;
  siret: string;
  pcru: string;
  clientTypology: string;
  dec: string;
  dr: string;
  sgRating: string;
  markKeyClientPage: string;
  _links?: {
    logo: {
      href: string;
      method: "GET";
    };
    self: {
      href: string;
      method: "GET";
    };
  };
}

export interface CountryDto {
  country?: string;
  name?: string;
  iso3?: string;
  iso2?: string;
  filtered?: boolean;
}

export interface CoverageDto {
  id?: string;
  person: UserDto;
  role?: string;
  function?: string;
  startDate?: Date;
  clientId?: string;
  activity?: string;
}

export interface CoveragesDto {
  coverages: CoverageDto[];
  page: number;
  pageSize: number;
  uri: string;
  firstPageUri: string;
}

export interface ParentClientDto {
  parentId?: AccountId;
}

export interface StrategicClientDto {
  current?: StrategicDto;
  previous?: StrategicDto;
}

export interface StrategicDto {
  isTarget?: boolean;
  segmentation?: string;
  countryAxis?: string;
  countryOfBr?: string;
  countryOfCoverage?: string;
  entryDate?: Date;
  exitDate?: Date;
  franchise?: string;
}

export interface TieringDto {
  isTierOne?: boolean;
}

export interface UserDto {
  firstname?: string;
  lastname?: string;
  email: string;
  team?: string;
  id?: string;
}

export interface PortfolioDto {
  isInMyPortfolio?: boolean;
  inPortfolioCount?: number;
}

export type Properties =
  | "bdrId"
  | "parent"
  | "country"
  | "target"
  | "strategic"
  | "tiering"
  | "coverage"
  | "coverageWithActivity"
  | "mainAddress"
  | "addresses"
  | "computedClientRootId"
  | "clientIds";

export interface SearchCriteria {
  sort?: "default" | "smartSort";
  name?: string;
  clientComm?: string;
  clientLegal?: string;
  clientRct?: string;
  clientId?: string;
  clientMnemonic?: string;
  coveredByEmail?: string;
  customListId?: string;
  after?: Date;
  before?: Date;
  onlyActive?: boolean;
  onlyTierOne?: boolean;
  inherit?: boolean;
  properties?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const searchProperties: Properties[] = ["bdrId", "country", "clientIds"];

export interface Mifid2AccountDto {
  status: string;
  url?: string;
}

export interface ShortAccount {
  id: string;
  name: string;
  country: string;
  level: string;
}
