import { h } from "preact";
import { ClientDto } from "common/types/account";
import { highlight } from "Sgb4/common/strings/Highlight";
import { Icon } from "Sgb4/common/Components/Icon";
import { get } from "lodash";

export const renderLineItem = (
  { name, clientIds, country, portfolio }: ClientDto,
  searchTerm: string
): JSX.Element => (
  <span>
    <span>{highlight(searchTerm, name)}</span>
    <span className="ml-1 text-secondary">
      ({get(clientIds, "internalId.level", null)})
    </span>
    <span className="ml-1 text-secondary">{country ? country.iso3 : ""}</span>
    {portfolio && portfolio.isInMyPortfolio ? (
      <span className="ml-1">
        <Icon iconName="account_balance_wallet" classNames="text-info" />
      </span>
    ) : null}
  </span>
);
