import { ClientDto, ClientOutput } from "common/types/account";
import { get, map } from "lodash";

interface ClientDtoOutput {
  detail: {
    accounts: ClientOutput[];
  };
}

export const mapToClientOutputDto = (
  accounts: ClientDto[]
): ClientDtoOutput => {
  return {
    detail: {
      accounts: map<ClientDto, ClientOutput>(
        accounts,
        ({ clientIds, id, name, country }) => ({
          bdrCommercialId: get(clientIds, "bdrCommercialId.id", ""),
          bdrCommercialLevel: get(clientIds, "bdrCommercialId.level", ""),
          bdrLegalId: get(clientIds, "bdrLegalId.id", ""),
          bdrLegalLevel: get(clientIds, "bdrLegalId.level", ""),
          icId: id,
          name,
          bdrMnemonic: get(clientIds, "bdrMnemonic"),
          rctId: get(clientIds, "rctId"),
          countryName: get(country, "country", ""),
          icLevel: get(clientIds, "internalId.level"),
        })
      ),
    },
  };
};
