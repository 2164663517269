import {
  searchAccountByTermAndLevel,
  getAccountsById,
  getAccountsByBdrId,
} from "Sgb4/common/api/accounts/account.api";
import { ClientDto } from "common/types/account";
import { find, map, flatten, uniqBy } from "lodash";
import {
  limitBeforeSearch,
  ServiceParameters,
} from "Sgb4/common/api/api.common";
import { mapToReadClient } from "./mapper";

export type ReadAccount = ClientDto & { selected: ConstrainBoolean };

export interface AccountIds {
  selectedIds: string[];
  readonlyIds: string[];
  selectedBdrIds: string[];
  readonlyBdrIds: string[];
  levels: string[];
}

export const fetchAccounts = (
  serviceParams: ServiceParameters,
  {
    selectedIds,
    readonlyIds,
    selectedBdrIds,
    readonlyBdrIds,
    levels,
  }: AccountIds
): Promise<ReadAccount[]> =>
  Promise.all([
    getAccountsById<ClientDto>(serviceParams, selectedIds),
    getAccountsById<ClientDto>(serviceParams, readonlyIds),
    getAccountsByBdrId(serviceParams, selectedBdrIds, levels),
    getAccountsByBdrId(serviceParams, readonlyBdrIds, levels),
  ]).then(([clients, readonlyClients, selectedBdrItems, readonlyBdrItems]) => [
    ...mapToUniqReadClients([...clients, ...flatten(selectedBdrItems)], false),
    ...mapToUniqReadClients(
      [...readonlyClients, ...flatten(readonlyBdrItems)],
      true
    ),
  ]);

const mapToUniqReadClients = (clients: ClientDto[], isReadonly: boolean) =>
  uniqBy(
    map(clients, client => mapToReadClient(client, true, isReadonly)),
    ({ id }) => id
  );

export const fetchAccountsByterm = (
  searchTerm: string,
  { serviceParams, maxResultCount }: any,
  selectedAccounts: Array<ClientDto | undefined>,
  levels?: string[]
): Promise<ReadAccount[]> =>
  searchAccountByTermAndLevel(
    serviceParams,
    searchTerm,
    levels,
    limitBeforeSearch,
    maxResultCount
  ).then((accountsDto: ClientDto[]) =>
    map(accountsDto, account => {
      const selected =
        find(
          selectedAccounts,
          selectedAccount =>
            selectedAccount && selectedAccount.id === account.id
        ) != null;
      return mapToReadClient(account, selected, false);
    })
  );
