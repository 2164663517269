import {
  h,
  SgWidget,
  initializeWidgetConfiguration,
  WidgetConfigurationProvider,
} from "@sgwt-widget/core";
import { emit, props } from "skatejs";
import { WithSGB4 } from "Sgb4/common/WithSGB4/WithSGB4";
import WidgetTracker from "common/component/WidgetTracker/WidgetTracker";
import { initFetching } from "Sgb4/common/api/api.common";
import { isStringEmpty } from "Sgb4/common/strings/stringUtils";
import { AccountPicker } from "./AccountPicker";
import { skateJSErrorProps } from "Sgb4/newTagPicker/common/skateJs.typings";
import { ErrorProps } from "Sgb4/newTagPicker/common/typings";
import { ReadAccount } from "ic-sgb4-simple-accounts-picker/fetchAccounts";
import { mapToClientOutputDto } from "Sgb4/newTagPicker/mapperOutputDto/mapToClientOutputDto";
import { deprecationMessage } from "../common/depricationMessage";

interface Props extends ErrorProps {
  maxResultCount: number;
  placeholder: string;
  selectedId: string;
  readonlyId: string;
  userEmail: string;
  soaUrl?: string;
  ssoV2: boolean;
  selectedBdrId: string;
  levels: string[];
  size?: string;
}

export class SgWidgetSimpleAccountPicker extends SgWidget<Props> {
  public static is = "ic-sgb4-simple-account-picker";
  public static props = {
    ...skateJSErrorProps,
    placeholder: props.string,
    maxResultCount: props.number,
    selectedId: props.string,
    readonlyId: props.string,
    selectedBdrId: props.string,
    levels: props.array,
    userEmail: props.string,
    soaUrl: props.string,
    ssoV2: props.boolean,
    size: props.string,
  };
  public constructor() {
    super();
    deprecationMessage(SgWidgetSimpleAccountPicker.is);
  }

  public render({
    soaUrl,
    userEmail,
    maxResultCount,
    selectedId,
    readonlyId,
    ssoV2,
    placeholder,
    selectedBdrId,
    levels,
    inError,
    errorMessage,
    size,
  }: Props) {
    const defaultPlaceholder = isStringEmpty(placeholder)
      ? "Search account"
      : placeholder;
    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithSGB4>
          <AccountPicker
            serviceParams={initFetching(
              this.widgetConfiguration,
              soaUrl,
              undefined,
              {},
              userEmail,
              ssoV2
            )}
            inError={inError}
            errorMessage={errorMessage}
            placeholder={defaultPlaceholder}
            maxResultCount={maxResultCount}
            selectedId={selectedId}
            readonlyId={readonlyId}
            selectedBdrId={selectedBdrId}
            levels={levels}
            size={size}
            hostName={SgWidgetSimpleAccountPicker.is}
            onChange={this.handleOnAccountSelectionChange}
            onReady={this.handleOnAccountReady}
          />
          <WidgetTracker
            widgetName={SgWidgetSimpleAccountPicker.is}
            env={this.widgetConfiguration.environment}
          />
        </WithSGB4>
      </WidgetConfigurationProvider>
    );
  }

  private handleOnAccountReady = (output?: ReadAccount) => {
    emit(
      this,
      `${SgWidgetSimpleAccountPicker.is}--ready`,
      mapToClientOutputDto(output ? [output] : [])
    );
  };

  private handleOnAccountSelectionChange = (output?: ReadAccount) => {
    emit(
      this,
      `${SgWidgetSimpleAccountPicker.is}--changed`,
      mapToClientOutputDto(output ? [output] : [])
    );
  };
}

initializeWidgetConfiguration(SgWidgetSimpleAccountPicker);
customElements.define(
  SgWidgetSimpleAccountPicker.is,
  SgWidgetSimpleAccountPicker
);
