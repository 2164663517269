import { GetCriteria } from "common/api/types";
import {
  Properties,
  SearchCriteria,
  searchProperties,
} from "common/types/account";
import { normalize, splitBy } from "common/utils";
import { groupBy, isEmpty, join, map } from "lodash";

interface Token {
  type: string;
  value: string;
}

const tokenize = (arr: string[]): Token[] =>
  map(arr, s => {
    const splitted = splitBy(":")(s);
    const type = splitted.length === 2 ? splitted[0] : "name";
    const value = splitted.length === 2 ? splitted[1] : splitted[0];
    return { type, value };
  });

const extract = (dic: { [key: string]: Token[] }) => (key: string) =>
  join(
    map(dic[key], item => item.value),
    " "
  );

export const toSearchCriteria = (
  userEmail: string,
  terms: string
): SearchCriteria => {
  const sort = "smartSort";

  const merge = (arr: Token[]): SearchCriteria => {
    const extractor = extract(groupBy(arr, "type"));
    const clientComm = extractor("commercial");
    const clientId = extractor("id");
    const clientLegal = extractor("legal");
    const clientRct = extractor("rct");
    const coveredByEmail =
      extractor("from") === "database" ? undefined : userEmail;
    const customListId = extractor("customlist");
    const name = extractor("name");

    return {
      clientComm,
      clientId,
      clientLegal,
      clientRct,
      coveredByEmail,
      customListId,
      name,
      properties: searchProperties.join(","),
      sort,
    };
  };

  return merge(tokenize(normalize(terms)));
};

export const toGetCriteria = (
  ids: string[],
  properties: Properties[]
): GetCriteria => ({
  ids,
  properties: isEmpty(properties) ? undefined : join(properties, ","),
});
